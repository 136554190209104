import { setCMSBarWidth } from './cms-bar-width'


import { search_icon_click, search_input_keyup, aldryn_search_submit } from './search'
import { init_serial } from './serial-slider'

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.search-icon').forEach(search => {
        search.addEventListener('click', search_icon_click)
    })
    document.getElementById('search-input').addEventListener('keyup', search_input_keyup)
    document.getElementById('aldryn-search').addEventListener('submit', aldryn_search_submit)

    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    for (const item of document.querySelectorAll('.page-navigation li a')) {
        const itemHref = item.getAttribute('href')
        if (typeof itemHref === 'string' && itemHref.includes('#link-hide')) {
            item.removeAttribute('href')
        }
    }

    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    // Adding icons by category
    for (const article of document.querySelectorAll('article.article')) {
        const category = article.querySelector('.category')
        const visual = article.querySelector('.visual')
        if (category && visual) {
            for (const categoryText of category.querySelectorAll('a')) {
                category.previousElementSibling.insertAdjacentHTML('afterbegin',
                    `
            <div
            class="category-icon
            ${categoryText.textContent.toLowerCase().trim().replace(/\s+/g, '')}-icon">
            </div>
            `
                )
            }
        }
    }

    // Move video to the Article beginning and hide featured picture.
    const videoHosting = document.querySelector('article.article .content iframe')
    const videoSource = document.querySelector('article.article .content video')

    const mediaSource = videoHosting ? videoHosting : videoSource
    if (mediaSource) {
        const picture = document.querySelector('article.article p.article-featured-picture')
        picture.querySelector('img').remove()
        picture.prepend(mediaSource)
    }

    // Move buttons under p.article-featured-picture
    const articlePage = document.querySelector('.slug-ke-stazeni-article')
        ? document.querySelector('.slug-ke-stazeni-article')
        : document.querySelector('.slug-download-article')

    if (articlePage) {
        const picture = articlePage.querySelector('article.article p.article-featured-picture')
        for (const contentBtn of articlePage.querySelectorAll('.content .btn')) {
            picture.append(contentBtn)
        }
    }

    for (const article of document.querySelectorAll('.newsblog-serial')) {
        init_serial(article)
    }

    // Menu language chooser
    // Support for first element with class active
    // Support for rotate arrow for language chooser
    const languageChooser = document.querySelector('.position-lg-relative .language-chooser')
    const activeLanguageChooser = languageChooser.querySelector('.lang.active')
    const lastLanguageChooser = languageChooser.querySelector('.lang:last-of-type')

    !languageChooser.querySelector('.lang.active:first-of-type') ? languageChooser.prepend(activeLanguageChooser) : null

    lastLanguageChooser.addEventListener('mouseover', function() {
        this.previousElementSibling.classList.add('lang-hover')
    })

    lastLanguageChooser.addEventListener('mouseout', function() {
        this.previousElementSibling.classList.remove('lang-hover')
    })

    // Remove links from section Nabizíme to article page
    for (const article of document.querySelectorAll('.slug-nabizime article.article')) {
        article.querySelector('.visual').prepend(article.querySelector('.visual img'))
    }

    // Remove section 'Může vás zajímat' if related articles are empty
    for (const section of document.querySelectorAll('.aldryn-download')) {
        const articleChildNodes = section.querySelector('.related-article li')
        if (articleChildNodes && articleChildNodes.childNodes.length <= 1) {
            section.remove()
        }
    }

    // Action page - move action table
    const article = document.querySelector('.slug-akce-article article.article')
    const table = article ? article.querySelector('.table-actions') : null
    if (article && table) {
        article.prepend(table)
    }

    // Split Category list - months and others
    const categoryList = document.querySelector('.aldryn-category > ul')

    const createMonthList = (item) => {
        let monthList = categoryList.parentNode.querySelector('.month-list')
        if (!monthList) {
            categoryList.insertAdjacentHTML('afterend', `<ul class="month-list"></ul>`)
            monthList = categoryList.parentNode.querySelector('.month-list')
        }
        monthList.append(item)
    }

    const splitCategory = () => {
        const CZECH_MONTH = ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec']
        for (const item of categoryList.querySelectorAll('li')) {
            const itemMonth = item.querySelector('a').textContent
            if (CZECH_MONTH.includes(itemMonth)) {
                createMonthList(item)
            }
        }
    }

    categoryList ? splitCategory() : null

    // Changing the text of the thank you for subscribing to the newsletter
    for (const newsletter of document.querySelectorAll('.newsletter-subscription')) {
        if (!newsletter.querySelector('form')) {
            newsletter.querySelector('p').textContent = newsletter.dataset.thanks
        }
    }

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})
